<template>
	<div id="home" class="is-relative">
		<Navbar />
		<div class="first has-background-primary"></div>
		<div class="hero is-fullheight is-relative">
			<div class="hero-body is-align-items-start mt-6">
				<div class="container is-not-relative">
					<h1 class="title is-size-1-desktop is-size-2-touch has-text-weight-bold has-text-white">Tudo o que precisa<br />numa só plataforma</h1>
					<h2 class="subtitle mx-auto mt-5 has-text-white">
						O ioConnect é uma plataforma de transição digital que permite um vasto conjunto de ações centralizadas no mesmo local, o que permite poupar tempo, poupar
						recursos, aproximar as pessoas e facilitar o processo de transição digital da empresa.
					</h2>
					<figure class="image"><img src="@/assets/svg/plataform.png" alt="" /></figure>
					<figure class="image logo-right"><img src="@/assets/svg/logo.png" alt="" /></figure>
					<figure class="image logo-left"><img src="@/assets/svg/logo.png" alt="" /></figure>
				</div>
			</div>
		</div>
		<div class="hero is-white">
			<div id="solution" class="hero-body">
				<div class="container is-not-relative">
					<h1 class="title is-size-1-desktop is-size-2-touch has-text-weight-bold has-text-dark">A Solução</h1>
					<h2 class="subtitle mx-auto my-6 is-size-7-desktop is-size-6-touch has-text-black">
						O <span class="has-text-primary has-text-weight-medium">ioConnect</span> centraliza e agrega todas as informações existentes numa empresa num só local. A
						plataforma permite funções ilimitadas que podem ir desde a gestão, comunicação entre departamentos, equipas, clientes ou fornecedores, simplificando os
						processos. O <span class="has-text-primary has-text-weight-medium">ioConnect</span> é uma plataforma modular com elevada capacidade de otimização e
						adaptabilidade à realidade e necessidade de cada empresa.
					</h2>
					<div class="columns is-mobile is-multiline">
						<div class="column is-2-widescreen is-4-desktop is-6-touch">
							<div class="card p-3 is-shadowless">
								<div class="card-body">
									<figure class="image m-auto" :class="isMobile ? 'is-96x96' : 'is-128x128'"><img src="@/assets/svg/analisar.png" alt="" /></figure>
									<h3 class="title is-size-4-desktop is-size-5-touch mt-5 has-text-dark">Analisar</h3>
								</div>
							</div>
						</div>
						<div class="column is-2-widescreen is-4-desktop is-6-touch">
							<div class="card p-3 is-shadowless">
								<div class="card-body">
									<figure class="image m-auto" :class="isMobile ? 'is-96x96' : 'is-128x128'"><img src="@/assets/svg/comunicar.png" alt="" /></figure>
									<h3 class="title is-size-4-desktop is-size-5-touch mt-5 has-text-dark">Comunicar</h3>
								</div>
							</div>
						</div>
						<div class="column is-2-widescreen is-4-desktop is-6-touch">
							<div class="card p-3 is-shadowless">
								<div class="card-body">
									<figure class="image m-auto" :class="isMobile ? 'is-96x96' : 'is-128x128'"><img src="@/assets/svg/gerir.png" alt="" /></figure>
									<h3 class="title is-size-4-desktop is-size-5-touch mt-5 has-text-dark">Gerir</h3>
								</div>
							</div>
						</div>
						<div class="column is-2-widescreen is-4-desktop is-6-touch">
							<div class="card p-3 is-shadowless">
								<div class="card-body">
									<figure class="image m-auto" :class="isMobile ? 'is-96x96' : 'is-128x128'"><img src="@/assets/svg/integrar.png" alt="" /></figure>
									<h3 class="title is-size-4-desktop is-size-5-touch mt-5 has-text-dark">Integrar</h3>
								</div>
							</div>
						</div>
						<div class="column is-2-widescreen is-4-desktop is-6-touch">
							<div class="card p-3 is-shadowless">
								<div class="card-body">
									<figure class="image m-auto" :class="isMobile ? 'is-96x96' : 'is-128x128'"><img src="@/assets/svg/monitorizar.png" alt="" /></figure>
									<h3 class="title is-size-4-desktop is-size-5-touch mt-5 has-text-dark">Monitorizar</h3>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="hero is-fullheight is-relative">
			<div id="benefits" class="hero-body">
				<figure class="image girl">
					<div class="square"></div>
					<img src="@/assets/svg/girl.png" alt="" />
				</figure>

				<div class="container">
					<div class="columns is-mobile">
						<div :class="isMobile && 'card has-shadow'" class="column is-offset-5-desktop is-7-desktop is-12-touch has-text-left">
							<h1 class="title is-size-1-desktop is-size-2-touch has-text-weight-bold has-text-dark has-text-centered-touch">Vantagens</h1>
							<h2 class="subtitle mx-auto my-6 has-text-black">
								O <span class="has-text-primary has-text-weight-medium">ioConnect</span> traz inúmeras vantagens e isso faz com que seja uma plataforma única que
								fará da transição digital da sua empresa um processo simples e totalmente adaptado à sua realidade.
							</h2>
							<div class="has-border-grey p-3 my-5">
								<div class="is-flex is-align-items-center">
									<figure class="image is-48x48">
										<img src="@/assets/svg/control.png" alt="" />
									</figure>
									<p class="ml-4 has-text-weight-semibold is-size-5 has-text-dark">Maior Controlo dos processos e tarefas</p>
								</div>
							</div>
							<div class="has-border-grey p-3 my-5">
								<div class="is-flex is-align-items-center">
									<figure class="image is-48x48">
										<img src="@/assets/svg/info.png" alt="" />
									</figure>
									<p class="ml-4 has-text-weight-semibold is-size-5 has-text-dark">Facilidade e rapidez no acesso à informação</p>
								</div>
							</div>
							<div class="has-border-grey p-3 my-5">
								<div class="is-flex is-align-items-center">
									<figure class="image is-48x48">
										<img src="@/assets/svg/necessidades.png" alt="" />
									</figure>
									<p class="ml-4 has-text-weight-semibold is-size-5 has-text-dark">Adaptado às necessidades de cada colaborador</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="hero is-fullheight">
			<div id="features" class="hero-body">
				<div class="container">
					<h1 class="title is-size-1-desktop is-size-2-touch has-text-weight-bold has-text-dark mb-6">Funcionalidades</h1>
					<div class="columns is-multiline is-centered is-mobile">
						<div class="column is-4-widescreen is-12-touch is-6-desktop">
							<div class="has-border-primary p-5 is-flex is-flex-direction-column is-align-items-flex-start has-text-left">
								<figure class="image is-64x64"><img src="@/assets/svg/monitorizar.png" alt="" /></figure>
								<h3 class="title is-4 mt-5 has-text-dark">Configuração de alertas e notificações</h3>
								<p class="is-size-6">Receba alertas em tempo-real e controle todos os processos e máquinas da sua empresa.</p>
							</div>
						</div>
						<div class="column is-4-widescreen is-12-touch is-6-desktop">
							<div class="has-border-primary p-5 is-flex is-flex-direction-column is-align-items-flex-start has-text-left">
								<figure class="image is-64x64"><img src="@/assets/svg/monitorizar.png" alt="" /></figure>
								<h3 class="title is-4 mt-5 has-text-dark">Controle tudo da sua empresa</h3>
								<p class="is-size-6">Controle máquinas, sistemas, pessoal e equipas à distância de uma plataforma.</p>
							</div>
						</div>
						<div class="column is-4-widescreen is-12-touch is-6-desktop">
							<div class="has-border-primary p-5 is-flex is-flex-direction-column is-align-items-flex-start has-text-left">
								<figure class="image is-64x64"><img src="@/assets/svg/monitorizar.png" alt="" /></figure>
								<h3 class="title is-4 mt-5 has-text-dark">Flexível e escalável</h3>
								<p class="is-size-6">Flexível à sua empresa e escaláveis à medida das suas necessidades.</p>
							</div>
						</div>
						<div class="column is-4-widescreen is-12-touch is-6-desktop">
							<div class="has-border-primary p-5 is-flex is-flex-direction-column is-align-items-flex-start has-text-left">
								<figure class="image is-64x64"><img src="@/assets/svg/monitorizar.png" alt="" /></figure>
								<h3 class="title is-4 mt-5 has-text-dark">Plataforma Omnicanal</h3>
								<p class="is-size-6">Melhore a experiência do seu cliente e aproxime-o da sua empresa.</p>
							</div>
						</div>
						<div class="column is-4-widescreen is-12-touch is-6-desktop">
							<div class="has-border-primary p-5 is-flex is-flex-direction-column is-align-items-flex-start has-text-left">
								<figure class="image is-64x64"><img src="@/assets/svg/monitorizar.png" alt="" /></figure>
								<h3 class="title is-4 mt-5 has-text-dark">In-Chat</h3>
								<p class="is-size-6">Comunique com clientes, fornecedores, parceiros, departamentos e equipas numa plataforma.</p>
							</div>
						</div>
						<div class="column is-4-widescreen is-12-touch is-6-desktop">
							<div class="has-border-primary p-5 is-flex is-flex-direction-column is-align-items-flex-start has-text-left">
								<figure class="image is-64x64"><img src="@/assets/svg/monitorizar.png" alt="" /></figure>
								<h3 class="title is-4 mt-5 has-text-dark">Dashboards interativos</h3>
								<p class="is-size-6">Informação sempre atualizada à medida de cada utilizador</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="hero is-fullheight">
			<div id="integrations" class="my-auto">
				<div class="container">
					<h1 class="title is-size-1-desktop is-size-2-touch has-text-weight-bold">Integrações</h1>
					<h2 class="is-size-5 mx-auto my-6">Traga todas as suas ferramentas para <span class="has-text-primary has-text-weight-medium">um único lugar</span>.</h2>
					<b-tabs
						@mouseenter.native="stopAutoPlayCarousels"
						@mouseleave.native="startAutoPlayCarousels"
						v-model="integrationsTabActive"
						v-if="Integrations"
						position="is-centered"
						class="block px-5"
					>
						<b-tab-item label="Comunicação">
							<b-carousel-list
								v-model="carousels.communication"
								:arrow-hover="false"
								class="mt-5 is-shadowless"
								icon-size="is-medium"
								:data="Integrations.communication"
								:items-to-show="integrationsToShow"
								:repeat="true"
							/>
						</b-tab-item>
						<b-tab-item label="Gestão">
							<b-carousel-list
								v-model="carousels.managment"
								:arrow-hover="false"
								class="mt-5 is-shadowless"
								icon-size="is-medium"
								:data="Integrations.managment"
								:items-to-show="integrationsToShow"
								:repeat="true"
							/>
						</b-tab-item>
						<b-tab-item label="Sistemas/Máquinas">
							<b-carousel-list
								v-model="carousels.systems"
								:arrow-hover="false"
								class="mt-5 is-shadowless"
								icon-size="is-medium"
								:data="Integrations.systems"
								:items-to-show="integrationsToShow"
								:repeat="true"
							/>
						</b-tab-item>
						<b-tab-item label="Produtividade">
							<b-carousel-list
								v-model="carousels.productivity"
								:arrow-hover="false"
								class="mt-5 is-shadowless"
								icon-size="is-medium"
								:data="Integrations.productivity"
								:items-to-show="integrationsToShow"
								:repeat="true"
							/>
						</b-tab-item>
					</b-tabs>
				</div>
			</div>
		</div>
		<div class="hero is-fullheight">
			<div id="faq" class="hero-body">
				<figure class="image man">
					<img src="@/assets/svg/man.svg" alt="" />
				</figure>
				<div class="container">
					<div class="columns is-mobile">
						<div :class="isMobile && 'card has-shadow py-5'" class="column is-offset-5-desktop is-7-desktop is-12-touch has-text-left">
							<h1 class="title is-size-1-desktop is-size-2-touch has-text-weight-bold has-text-dark mb-5 has-text-centered-touch">FAQ</h1>
							<b-tabs position="is-centered" class="block">
								<b-tab-item label="ioConnect">
									<Collapse
										:open="collapseOpen == index + 10"
										@open="collapseOpen = index + 10"
										v-for="(collapse, index) of collapses.ioConnect"
										:key="index"
										:title="collapse.title"
										:text="collapse.text"
									/>
								</b-tab-item>
								<b-tab-item label="Transição digital">
									<Collapse
										:open="collapseOpen == index + 100"
										@open="collapseOpen = index + 100"
										v-for="(collapse, index) of collapses.transitionDigital"
										:key="index"
										:title="collapse.title"
										:text="collapse.text"
									/>
								</b-tab-item>
								<b-tab-item label="IOTech">
									<Collapse
										:open="collapseOpen == index + 1000"
										@open="collapseOpen = index + 1000"
										v-for="(collapse, index) of collapses.ioTech"
										:key="index"
										:title="collapse.title"
										:text="collapse.text"
									/>
								</b-tab-item>
							</b-tabs>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="hero is-fullheight">
			<div id="contacts" class="hero-body">
				<div class="container">
					<h1 class="title is-size-1-desktop is-size-2-touch has-text-weight-bold my-6">Contactos</h1>
					<div class="columns is-mobile is-centered is-multiline">
						<div class="column is-6-desktop is-12-touch" :class="isMobile && 'mb-6'">
							<form @submit.prevent="">
								<b-field>
									<b-input required aria-placeholder="Nome" placeholder="Nome" name="name"></b-input>
								</b-field>
								<b-field>
									<b-input required aria-placeholder="Email" placeholder="Email" type="email" name="email"></b-input>
								</b-field>
								<b-field>
									<b-input maxlength="360" required aria-placeholder="Mensagem" name="message" placeholder="Mensagem" type="textarea"></b-input>
								</b-field>
								<b-field class="has-text-left">
									<b-button type="is-primary" class="p-5" native-type="submit">Enviar</b-button>
								</b-field>
							</form>
						</div>
						<div class="column is-offset-1-desktop is-4-desktop is-12-touch">
							<div class="is-flex is-align-items-center mb-5">
								<figure class="image mr-5" :class="isMobile ? 'is-48x48' : 'is-64x64'"><img src="@/assets/svg/phone.svg" alt="" /></figure>
								<a class="has-text-weight-medium has-text-dark" href="tel:+351302002958">+351 302 002 958</a>
							</div>
							<div class="is-flex is-align-items-center my-5">
								<figure class="image mr-5" :class="isMobile ? 'is-48x48' : 'is-64x64'"><img src="@/assets/svg/email.svg" alt="" /></figure>
								<a class="has-text-weight-medium has-text-dark" href="mailto:ioconnect@iotech.pt">ioconnect@iotech.pt</a>
							</div>
							<div class="is-flex is-align-items-center my-5">
								<figure class="image mr-5" :class="isMobile ? 'is-48x48' : 'is-64x64'"><img src="@/assets/svg/location.svg" alt="" /></figure>
								<a class="has-text-left has-text-weight-medium has-text-dark" target="__blank" href="https://g.page/iotechpis"
									>Av. da Riopele 946, 4770-405<br />Pousada de Saramagos</a
								>
							</div>
							<p class="has-text-left has-text-weight-semibold">Siga-nos nas nossas redes sociais</p>
							<div class="is-flex is-align-items-center is-justify-content-space-between mt-5">
								<a class="has-text-left has-text-weight-medium has-text-dark" target="__blank" href="https://www.facebook.com/iotechpis/">
									<figure class="image" :class="isMobile ? 'is-48x48' : 'is-64x64'">
										<img src="@/assets/svg/facebook.svg" alt="" />
									</figure>
								</a>
								<a class="has-text-left has-text-weight-medium has-text-dark" target="__blank" href="https://www.instagram.com/iotechpis/">
									<figure class="image" :class="isMobile ? 'is-48x48' : 'is-64x64'">
										<img src="@/assets/svg/instagram.svg" alt="" />
									</figure>
								</a>
								<a class="has-text-left has-text-weight-medium has-text-dark" target="__blank" href="https://www.twitter.com/iotechpis/">
									<figure class="image" :class="isMobile ? 'is-48x48' : 'is-64x64'">
										<img src="@/assets/svg/twitter.svg" alt="" />
									</figure>
								</a>
								<a class="has-text-left has-text-weight-medium has-text-dark" target="__blank" href="https://www.youtube.com/channel/UCPBz6T3dt5EPfqEl0b9IB5w">
									<figure class="image" :class="isMobile ? 'is-48x48' : 'is-64x64'">
										<img src="@/assets/svg/youtube.svg" alt="" />
									</figure>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer />
	</div>
</template>

<script>
	import Navbar from './components/Navbar.vue';
	import Collapse from '@/components/Collapse.vue';
	import Footer from './components/Footer.vue';
	import axios from 'axios';
	export default {
		name: 'App',
		components: {
			Navbar,
			Collapse,
			Footer,
		},
		data() {
			return {
				isMobile: false,
				Integrations: null,
				autoPlayTime: 3500,
				autoPlayTimer: null,
				integrationsTabs: 3,
				integrationsTabActive: 0,
				carousels: {
					communication: 0,
					managment: 0,
					systems: 0,
					productivity: 0,
				},
				collapseOpen: 0,
				collapses: {
					ioConnect: [
						{
							title: 'Porquê o ioConnect?',
							text: `O ioConnect é a única plataforma que se adapta totalmente à sua empresa e a si. A realidade é que generalizar o processo de transição digital a todas as empresas faria com que a transição não estivesse adaptada àquilo que a sua empresa precisa e as pessoas nas empresas é que se tinham de adaptar a essa transição. Mas com o ioConnect, a plataforma e a empresa adaptam-se mutuamente uma à outra, implementando diretamente a plataforma nas bases da sua empresa, otimizando para aquilo que ela verdadeiramente precisa.<br><br> Com o ioConnect consegue ter tudo numa só plataforma, e isto traz inúmeras vantagens pelo facto dos processos estarem centralizados num só local. Poupará tempo, recursos, terá uma maior facilidade em aceder a dados, a comunicar com sua equipa, fornecedores, máquinas, instalações, sistema de faturação, entre muitos outros.<br><br> Ainda, a facilidade com que podemos instalar novas funcionalidades no ioConnect fazem desta plataforma, a plataforma do futuro. Acompanhamos o crescimento da sua empresa e o ioConnect também.`,
						},
						{
							title: 'Como sabem o que a minha empresa precisa?',
							text: `A nossa equipa é especializada em transição digital e analisamos o seu negócio de modo a saber aquilo que ele precisa, onde posteriormente desenham um plano de atuação para lhe apresentar. Não existe uma plataforma base pois isso seria estar a adaptar a sua empresa à plataforma, e o que a IOTech oferece é precisamente o inverso.<br><br><b>A plataforma deve adaptar-se às necessidades da sua empresa e às suas.</b><br><br> Para isso basta entrar em contacto connosco para agendar uma reunião e, se quiser ficar a saber mais do que a IOTech pode fazer por si e pelo seu negócio, visite <a class="has-text-primary" href="https://iobusiness.iotech.pt/" target="__blank">https://iobusiness.iotech.pt/</a>.`,
						},
						{
							title: 'Mas o que posso integrar com o ioConnect?',
							text: 'Tudo aquilo que a empresa necessitar e o colaborador desejar. Com o ioConnect, pode ligar máquinas, sistemas, sistemas de faturação, pessoas (chat), aplicações, calendários, entre outros.',
						},
						{
							title: 'O ioConnect estará acessível via telemóvel?',
							text: 'Sim, o ioConnect é acessível em qualquer dispositivo, seja ele telemóvel, tablet ou computador e em qualquer lugar!',
						},
						{
							title: 'Poderei ser informado sobre paragens, as últimas encomendas, problemas entre outras informações em tempo-real?',
							text: 'Sim, a plataforma recolhe informação em tempo-real que lhe é demonstrada. Portanto, independentemente de onde estiver, terá acesso a todos os processos da sua empresa à distância de uma plataforma.',
						},
					],
					transitionDigital: [
						{
							title: 'O que é transição digital?',
							text: `É o processo no qual as empresas fazem uso da tecnologia para melhorar o seu desempenho, passando esta a estar numa posição central nos processos da sua empresa, ou que pode trazer vários benefícios como, por exemplo, redução de custos, aceleração dos processos e acesso à informação, aumento da eficácia e da produtividade, entre outros.<br><br> Fazer a transição digital não é, como muitos pensam, estar no mundo digital (ter redes sociais, site, app, etc) é muito mais que isso e tem um impacto mais direto na sua empresa.`,
						},
						{
							title: 'Porquê fazer a transição digital da minha empresa?',
							text: `A capacidade de uma empresa de efetuar a transição digital vai filtrar quem se mantém no mercado e quem não será capaz de o fazer.<br> A realidade é que a transição digital pode trazer inúmero benefícios à sua empresa, tais como:<br><br> - <b>Redução de custos</b> - através da análise da sua empresa, é possível eliminar gastos desnecessários e reduzir o tempo de algumas tarefas que lhe conferem mais tempo para outros processos; <br><br> - <b>Acesso a informação mais rápido</b> - a informação é poder e esta é indispensável a uma empresa. Quando bem organizada e rapidamente acessível, esta informação pode-se transformar numa mais-valia que pode ser utilizada para melhorar o desempenho da sua empresa; <br><br> - <b>Aumento da eficácia e da produtividade</b> - existem algumas ações que lhe conferem demasiado tempo e lhe retiram eficácia e produtividade, como, por exemplo, inserir dados manualmente, falta de comunicação entre departamentos, tempo dispensados para processos. Com a transição digital estes processos passam a estar automatizados e centralizados numa plataforma que ajuda toda a empresa a ter um maior controlo dos processos e do que passa na empresa.`,
						},
					],
					ioTech: [
						{
							title: 'Quem é a IOTech?',
							text: `A IOTech é uma startup/empresa portuguesa de Investigação e Desenvolvimento (I&D) especializada no desenvolvimento de Aplicações (APPs), plataformas online (Sites, lojas online, portais, entre outros), integração e interoperabilidade de sistemas e softwares à medida com uma base forte de Internet das Coisas (IoT) e Inteligência Artificial (AI).<br><br> As suas soluções têm como foco a digitalização dos processos de negócio (Indústria 4.0) e a Transição/ transformação digital das empresas.<br><br> A empresa criou a sua própria academia de formação e investigação, a ioAcademy, direcionada a alunos do ensino superior, com o objetivo de captar, formar e reter talentos.`,
						},
						{
							title: 'Como posso contactar a IOTech?',
							text: `- <b>Telefone</b>: 302 002 958 <br><br> - <b>Email</b>: iobusiness@iotech.pt`,
						},
						{
							title: 'Porque é que a IOTech é considerada uma empresa 6 em 1?',
							text: `A IOTech é uma empresa 6 em 1 pois consegue concentrar todos os processos num só parceiro. A IOTech elabora: <br><br> <b>1</b>. Estudo das necessidades <br><br> <b>2</b>. Modelação <br><br> <b>3</b>. Desenvolvimento <br><br> <b>4</b>. Implementação <br><br> <b>5</b>. Análise de Dados <br><br> <b>6</b>. Monitorização <br><br> Desde o primeiro momento até ao último, a IOTech estará sempre consigo. Saiba mais em iobusiness.iotech.pt e entre em contacto connosco.`,
						},
					],
				},
			};
		},
		created() {
			this.getIntegrations();
		},
		mounted() {
			addEventListener('resize', this.onMounted);
			this.onMounted();
			this.startAutoPlayCarousels();
		},
		computed: {
			integrationsToShow() {
				return this.isMobile ? 1 : 4;
			},
		},
		methods: {
			startAutoPlayCarousels() {
				this.autoPlayTimer = setInterval(() => {
					if (this.integrationsTabActive == 0) {
						if (this.carousels.communication >= Math.round(this.Integrations.communication.length / this.integrationsToShow) - 1) {
							this.carousels.communication = 0;
							this.integrationsTabActive++;
						} else {
							this.carousels.communication++;
						}
					} else if (this.integrationsTabActive == 1) {
						if (this.carousels.managment >= Math.round(this.Integrations.managment.length / this.integrationsToShow) - 1) {
							this.carousels.managment = 0;
							this.integrationsTabActive++;
						} else {
							this.carousels.managment++;
						}
					} else if (this.integrationsTabActive == 2) {
						if (this.carousels.systems >= Math.round(this.Integrations.systems.length / this.integrationsToShow) - 1) {
							this.carousels.systems = 0;
							this.integrationsTabActive++;
						} else {
							this.carousels.systems++;
						}
					} else if (this.integrationsTabActive == 3) {
						if (this.carousels.productivity >= Math.round(this.Integrations.productivity.length / this.integrationsToShow) - 1) {
							this.carousels.productivity = 0;
							this.integrationsTabActive = 0;
						} else {
							this.carousels.productivity++;
						}
					}
				}, this.autoPlayTime);
			},
			stopAutoPlayCarousels() {
				clearInterval(this.autoPlayTimer);
			},
			getIntegrations() {
				axios
					.get('/assets/integrations.json')
					.then((response) => {
						this.Integrations = response.data;
					})
					.catch((error) => {
						console.log(error);
					});
			},
			onMounted() {
				this.isMobile = window.innerWidth <= 1024;
				let left = document.querySelector('#benefits .container').getBoundingClientRect().left;
				document.querySelector('#benefits figure>img').style.paddingLeft = left + 'px';
			},
		},
	};
</script>

<style lang="scss" scoped>
	// import bulma mixins
	@import '@/assets/scss/_variables.scss';
	@import '~bulma/sass/utilities/mixins';

	#home > .has-background-primary.first {
		position: absolute;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
	}
	.hero.is-primary {
		margin-bottom: 15vh;
	}
	h2.subtitle {
		font-size: 1rem;
		max-width: 55rem;
	}

	figure.logo-right {
		position: absolute;
		top: 50%;
		right: 0;
		transform: translate(50%, -50%);
		opacity: 0.2;
	}
	figure.logo-left {
		position: absolute;
		top: 100%;
		left: 0;
		transform: translate(-50%, -50%);
		opacity: 0.2;
	}

	#solution {
		.columns {
			justify-content: center;
			@include widescreen {
				justify-content: space-between;
			}
		}
	}

	#features {
		.has-border-primary {
			height: 100%;
		}
	}

	#benefits {
		.girl {
			left: 0;
			position: absolute;

			.square {
				position: absolute;
				background-color: rgba(26, 141, 55, 0.2);
				border-radius: 0 3.0556rem 3.0556rem 0;
				bottom: 0;
				height: 90%;
				width: 100%;
				z-index: -1;
			}
			img {
				height: 32rem;
				padding-right: 3rem;
			}
		}
	}
	#faq {
		.man {
			position: absolute;
			left: 0;
			img {
				height: 32rem;
			}
		}
	}
</style>
