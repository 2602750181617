<template>
	<footer class="footer has-background-primary">
		<div class="is-flex is-justify-content-space-evenly is-align-items-center has-text-white">
			<figure class="image px-3"><img src="@/assets/svg/iotech.png" alt="" /></figure>
			<p class="px-3">© IOTech {{ currentYear }}, Todos os direitos reservados</p>
			<a class="px-3 has-text-white" href="">Termos de Consentimento</a>
			<a class="px-3 has-text-white" href="">Política de Privacidade</a>
		</div>
	</footer>
</template>

<script>
	export default {
		name: 'Footer',
		data() {
			return {
				currentYear: new Date().getFullYear(),
			};
		},
	};
</script>

<style></style>
